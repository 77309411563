import styled, { css } from 'styled-components'

import * as color from '../constants/colors'
import * as copy from './copy'

export type TagProps = {
  variant?: 'red' | 'light-green' | 'green' | 'outlined' | 'white' | 'yellow'
}

const BaseTag = styled(copy.TextTag)`
  align-items: center;
  border-radius: 20px;
  border-style: solid;
  border-width: 1.5px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 4px 10px 4px;
`

export const Tag = styled(BaseTag)<TagProps>`
  ${({ variant }) => {
    if (variant === 'red')
      return css`
        border-color: ${color.ERROR};
        background-color: ${color.ERROR};
        color: ${color.WHITE};
      `
    if (variant === 'light-green')
      return css`
        border-color: ${color.LIGHT_GREEN};
        background-color: ${color.LIGHT_GREEN};
        color: ${color.WHITE};
      `
    if (variant === 'green')
      return css`
        border-color: ${color.PINE_GREEN_1};
        background-color: ${color.PINE_GREEN_1};
        color: ${color.WHITE};
      `
    if (variant === 'outlined')
      return css`
        border-color: ${color.GRAY_3};
        background-color: transparent;
        color: ${color.GRAY_3};
      `
    if (variant === 'yellow')
      return css`
        border-color: transparent;
        background-color: ${color.YELLOW};
        color: ${color.GRAY_3};
      `
    if (variant === 'white')
      return css`
        border-color: ${color.WHITE};
        background-color: ${color.WHITE};
        color: ${color.GRAY_3};
      `
  }}
`

export const Wrapper = styled.div`
  color: ${color.WHITE};
  display: inline-flex;
  position: relative;

  ${Tag} {
    padding-right: 30px;
  }
`

export const IconWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 8px;
`
