import * as React from 'react'
import styled from 'styled-components'

import * as S from '../styles'
import * as color from '../constants/colors'

import * as icons from '../svg/icons'

export type TagProps = S.tag.TagProps & {
  onClose?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
} & React.HTMLAttributes<HTMLDivElement>

function TagComponent({ onClose, variant, children, ...baseTagProps }: TagProps): React.ReactElement {
  return (
    <>
      {onClose ? (
        <S.tag.Wrapper {...baseTagProps}>
          <S.tag.IconWrapper onClick={onClose}>
            <icons.Cross size={20} />
          </S.tag.IconWrapper>

          <S.tag.Tag variant="green">{children}</S.tag.Tag>
        </S.tag.Wrapper>
      ) : (
        <S.tag.Tag variant={variant} {...baseTagProps}>
          {children}
        </S.tag.Tag>
      )}
      {/* Ici une grande condition qui aurait pu etre refacto,
            cependant S.tag.Wrapper change le css des S.tag.Tag il est donc plus simple
            de conserver cette condition */}
    </>
  )
}

export const Tag = styled(TagComponent)`
  ${S.tag.IconWrapper}:hover + ${S.tag.Tag} {
    border-color: ${color.PINE_GREEN_2};
    background-color: ${color.PINE_GREEN_2};
  }
`
