import * as React from 'react'
import styled from 'styled-components'
import { useLinkWrapper } from '../hooks/useLinkWrapper'

import * as S from '../styles'

export type ButtonAsLinkProps = S.button.ButtonProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    iconLeft?: React.ReactNode
    iconRight?: React.ReactNode
  }

function ButtonAsLinkComponent(
  { children, href, iconLeft, iconRight, ...rest }: ButtonAsLinkProps,
  ref: React.Ref<HTMLAnchorElement>,
): React.ReactElement {
  const LinkWrapper = useLinkWrapper()
  return (
    <LinkWrapper href={href} passHref legacyBehavior>
      <S.button.ButtonAsLink ref={ref} {...rest}>
        {iconLeft}
        <S.button.Text>{children}</S.button.Text>
        {iconRight}
      </S.button.ButtonAsLink>
    </LinkWrapper>
  )
}

export const ButtonAsLink = styled(React.forwardRef(ButtonAsLinkComponent))``
